@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

.login-langin-banner {
    margin-top: -50px;
    padding-top: 70px;
    padding-bottom: 70px;
    background: url(../images/landing-image.jpg) center top no-repeat #B0C0BF;
    background-size: cover;
}
.after-reg-thanks {
    width: 100%;
    max-width: 518px;
    padding: 40px 44px;
    background: $primary;
    color: $white;
    & h2 {
        font: normal normal 500 70px/78px 'Noto Sans';
        letter-spacing: 0.53px;
    }
    & .after-reg-message {
        font: normal normal normal 18px/28px 'Noto Sans';
        letter-spacing: 0.32px;
    }
}
.palms {
    padding-top: 68px;
    padding-bottom: 68px;
    text-align: center;
    font-family: 'Noto Sans';
    & .d-flex > div {
        width: 78px;
        height: 108px;
        background: url(../images/palm-small.svg) center bottom no-repeat;
        &.palm {
            margin: 0 14px;
            width: 108px;
            background: url(../images/palm.svg) center bottom no-repeat;
        }
    }
    & .season-text {
        padding-top: 33px;
        padding-bottom: 14px;
        font-size: 28px;
        line-height: 40px;
        font-weight: bold;
        color: $primary;
    }
    & .palm-help {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: $gray-700;
    }
}

.palms500 {
    padding:50px 0;
    text-align: center;
    font-family: 'Noto Sans';
    color: #A2A4A0;
    & .d-flex > div {
        width: 137px;
        height: 213px;
        background: url(../images/palm-2.svg) center bottom no-repeat;
        &.palm {
            margin: 0 14px;
            width: 290px;
            background: url(../images/palm-large.svg) center bottom no-repeat;
        }
    }
    & .season-text {
        padding-top: 45px;
        padding-bottom: 14px;
        font-size: 28px;
        line-height: 40px;
        font-weight: bold;
        color: $primary;
    }
    & .palm-help {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: $gray-700;
    }
}
