// Import just the styles you need. Note that some stylesheets are required no matter what.

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

$lang: 'en';

@import "google-font";

//
// Override Bootstrap here
//
@import "custom-variables";

// @import "bootstrap/scss/root";
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group"; // Requires forms
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
.navbar-nav.main-nav {
    & .nav-link {
        border-bottom: $white solid 2px;
        margin-right: 24px;
    }
    & .nav-link.active {
        border-bottom-color: $green;
        // text-decoration: underline;
    }
}
input {
    font-family: $font-family-sans-serif;
}
// @import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";


// importing icons
@import "icons";

// Custom code
@import "header";
@import "card";
@import "content";
@import "badges";
@import "registration";
@import "login";
@import "appointments";
@import "edit-auction";
@import "invoices";
@import "my-wallet";
@import "multi-select";
@import "datepicker";
@import "price-range";
@import "footer";
@import "after-login";
