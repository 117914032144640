.financial-navs {
    padding-bottom: 50px;
    & a {
        display: inline-block;
        padding: 5px 10px;
        border-bottom: $white solid 2px;
        color: #787E6E;
        &.active {
            color: $primary;
            border-bottom: $primary solid 2px;
        }
        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }

}
.listing-item {
    border-bottom: #DEDFDD solid 1px;
}
.listing-item:last-child {
    border-bottom: 0;
}
.invoice-actions {
    padding-left: 10px;
    padding-bottom: 20px;
}
.listing-item {
    padding: 14px 10px 14px;
}
.inv-checkbox {
    padding-right: 15px;
}
.invoice-date {
    font-size: 0.625rem;
    line-height: 1em;
    letter-spacing: 1px;
    color: #6C6F69;
    text-transform: uppercase;
}
.invoice-id {
    padding: 6px 0;
    line-height: 1.375em;
    letter-spacing: 0.14px;
    color: #2A2E26;
}
.invoice-amount {
    font-size: 1.125rem;
    line-height: 1em;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #2A2E26;
}
.invoice-services {
    padding-top: 20px;
    font-size: 0.875rem;
    line-height: 1.28em;
    color: #6C6F69;
}
@media (min-width: map-get($grid-breakpoints, "md")) {
    .invoice-actions:last-child, .listing-item:last-child {
        border-bottom: 0;
    }
}
@media (min-width: map-get($grid-breakpoints, "md")) {
    .listing-item {
        padding: 6px 10px 14px;
    }
}