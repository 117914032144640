header {
    margin-bottom: 50px;
    background: #fff;
    & > .navbar {
        padding: 28px 2rem;
    }
    & nav.loggedin {
        padding-top: 22px;
        padding-bottom: 22px;
    }
    & .navbar-collapse {
        margin-top: 1.5rem;
    }
    & .navbar-light .nav-link {
        color: #6C6F69 !important;
        &.active, &:hover {
            color: $primary !important;
        }
    }
    & .navbar-light .navbar-toggler {
        padding: 6px 6px 0;
        border: 0;
    }
    &.sticky-top {
        box-shadow: #ededed 0px 0px 14px;
        &, & *{
            transition: all 200ms;
        }
    }
    & .dropdown-menu {
        margin-top:8px;
        border: 0;
        box-shadow: 0 0 1rem #ddd;
        font-size: 0.9375rem;
        line-height: 1.25rem;
        @if $lang == "ar" {
            font-family: "Noto Kufi Arabic",sans-serif;
        } @else {
            font-family: 'Noto Sans', sans-serif;
        }
        white-space: nowrap;
        color: #6C6F69;
        @if $lang == "ar" {
            left: 40px;
            right: auto;
        } @else {
            left: auto;
            right: 40px;
        }
        & > div {
            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: #dfdfdf solid 1px;
            &.menu-user {
                padding-top: 8px;
            }
            &.menu-logout {
                border-bottom: 0;
                padding-bottom: 10px;
            }
            &.menu-balance {
                padding-top: 0;
                padding-bottom: 0;
                & .balance-dbox {
                    @if $lang == "ar" {
                        border-left: #dfdfdf solid 1px;
                    } @else {
                        border-right: #dfdfdf solid 1px;
                    }
                }
            }
        }
    }
    & .nav-link.account {
        position: relative;
        width: 45px;
        height: 45px;
        border-radius: 50px;
        background: url(../images/account.svg) center 8px no-repeat $green-light;
        &.active {
            background: url(../images/account-active.svg) center 8px no-repeat $primary;
        }
        &:after {
            display:none;
        }
    }
    & .navbar-light .navbar-nav.nav-secondary {
        margin-left: 10px;
        & a { 
            font-size: 0.9375rem;
        }
    }
}
.navbar-toggler .dash {
    display: block;
    width: 30px;
    border-bottom: solid $primary 2px;
    margin-bottom: 6px;
}

.menu-dark-bg{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: 0;
    z-index: 1100;
    transition: all 200ms;
}
.menu-dark-bg.show{
    display: block;
    opacity: 0.4;
}
.menu-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1101;
}
#mobileMenus {
    position: absolute;
    top: 0;
    @if $lang == "ar" {
        left: -100%;
    } @else {
        right: -100%;
    }
    width: 90%;
    height: 100%;
    z-index: 1101;
    background: #fff;
    box-shadow: 0 0.5rem 1rem #999;
    transition: all 200ms;
    &.show{
        @if $lang == "ar" {
            left: 0;
        } @else {
            right: 0;
        }
    }
    & .menu-languages {
        margin: 0.7rem 1rem 1rem;
        border-top: #dfdfdf solid 1px;
        border-bottom: #dfdfdf solid 1px;
    } 
    & .menu-balance{
        line-height: 1.7em;
        border-top: #dfdfdf solid 1px;
        border-bottom: #dfdfdf solid 1px;
        & .balance-dbox {
            @if $lang == "ar" {
                border-left: #dfdfdf solid 1px;
            } @else {
                border-right: #dfdfdf solid 1px;
            }
        }
    }
    & .main-nav {
        margin: 18px 0;
    }
    & a.nav-link {
        padding: 18px 0 2px;
        margin-bottom: 16px;
        margin-right: 0;
        margin-left: 0;
        &.active{
            color: $primary !important;
        }
    }
}
#closeMenu {
    padding: 20px;
    position: absolute;
    font-size: 18px;
    color: #707070;
    top: 0;
    left: 0;
}
.user-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: url(../images/account.svg) center 8px no-repeat $green-light;
}
.menu-notifications i {
    margin-top:5px;
}
.mobile-menu {
    & .menu-languages a{
        display: inline-block;
        padding: 3px 1px;
        margin: 0 14px;
        color: $gray-900;
        border-bottom: $white solid 1px !important;
        &:hover {
            text-decoration: none;
            color: $primary;
        }
        &.active {
            color: $primary;
            border-bottom: $primary solid 1px !important;
        }
    }
    & .nav-link {
        display: inline-block;
        color: $gray-900 !important;
        & .active {
            color: $primary !important;
            border-bottom: $primary solid 1px !important;
        }
        &:hover {
            text-decoration: none;
        }
    }
}
.menu-notifications i, a.notifications {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(../images/notifications.svg) 0 0 no-repeat;
    @if $lang == "ar" {
        margin-left: 16px;
    } @else {
        margin-right: 16px;
    }
    & span {
        position: absolute;
        top: 0;
        @if $lang == "ar" {
            left: 0;
        } @else { 
            right: 0;
        }
        background: #E84343;
        width: 5px;
        height: 5px;
        border-radius: 50%;
    }
}

.nav-secondary .account.dropdown-toggle {
    margin-top: 5px;
    @if $lang == "ar" {
        margin-right: auto;
        margin-left: 1rem;
    } @else {
        margin-left: auto;
        margin-right: 1rem;
    }
}
.balance-dbox > div, .credit-dbox > div {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.88px;
    color: #6C6F69;
}
.top-name, .menu-balance, .credit-dbox {
    color: $gray-900;
}
.user-hello {
    position:relative;
    top: 16px;
    padding: 0.5rem;
    width: 280px;
    box-shadow: -1px 2px 8px #0000001A;
    border-radius: 0px 0px 0px 8px;
}
.top-nofication {
    padding: 5px;
    background: $red;
    color: $white;
    font-size: 0.75rem;
    line-height: 1em;
    width: 22px;
    text-align: center;
}
.top-nofication-bell {
    display: inline-block;
    width: 22px;
    height: 21px;
    background: url(../images/bell.svg) 0 3px no-repeat;
}
.top-name {
    padding-top: 6px;
    font-size: 0.9375rem;
    color: $gray-900;
}
.balance-box {
    padding-top: 10px;
    font-size: 0.75rem;
    & label {
        display: block;
        margin-bottom: 0.2rem;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: $gray-500;
    }
}
.balance-info {
    width:36%;
    border-right: #C6CEBA solid 1px;
}
.credit-limit {
    padding-left: 8px;
    width: 64%;
    & a {
        color: #6C6F69;
        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }
}

.lang-switch {
    display: inline-block;
    width: 26px;
    height: 34px;
    text-align: center;
    @if $lang == "ar" {
        background: url(../images/english.svg) 0 5px no-repeat;
    } @else {
        background: url(../images/arabic.svg) 0 5px no-repeat;
    }
}
.main-content {
    padding-top: 20px;
}

#headerNav {
    & .nav-link {
        display: inline-block;
        font-size: 0.8125rem;
        line-height: 2em;
        padding-top: 14px;
        &.lang-switch{
            border: 0;
        }
    }
    & #registerBtn {
        font-size: 1rem;
        padding: 12px 28px;
    }
    & .login-link{
        @if $lang == "ar" {
            margin-left: 10px;
        } @else {
            margin-right: 10px;
        }
    }
}
#registerBtn {
    margin-right: 22px;
    height: 53px;
    border-radius: 60px;
    color: $white !important;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
        color: $white;
    }
}
.nav-pills.position-sticky{ 
    top: 120px;
}
@media (min-width: map-get($grid-breakpoints, "md")) {
    .user-hello {
        position: absolute;
        right: 0;
        top: 0;
    }
    header .navbar-collapse {
        margin-top: 0;
    }
}
@media (min-width: map-get($grid-breakpoints, "lg")) {
    header.scrolling {
        & .navbar {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}
@if $lang == "ar"{
    .dropdown-toggle::after {
        margin-left: 0;
        margin-right: .255em;
    }
}
@media (min-width: 992px) {
    .top-search {
        & .form-control {
            width: 180px;
        }
    }
}
@media (min-width: 1200px) {
    .top-search {
        & .form-control {
            width: 360px;
        }
    }
}
