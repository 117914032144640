
// Toggle global options
$enable-gradients: false;
$enable-shadows: true;

// Customize some defaults
$body-color: #2A3D1C;
$body-bg: #ffffff;
$border-radius: .4rem;

$white:    #fff;
$gray-100: #F8FAF7;
$gray-200: #FBFCFA;
$gray-300: #E6EAE2;
$gray-400: #A9ACA3;
$gray-500: #787E6E;
$gray-600: #54584B;
$gray-700: #727B6C;
$gray-800: #2A3D1C;
$gray-900: #2A2E26;
$black:    #000;

$blue:    #2DAA26;
$blue-light: #C2D9F1;
$blue-lighter: #E0ECF8;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #B01220;
$red-light: #ECC2C9;
$red-lighter: #F6E0E4;
$orange:  #fd7e14;
$yellow:  #FFC107;
$green:   #2DAA26;
$teal:    #6DD329;
$cyan:    #007BFF;
$bid-color: #A55A2E;
$brown:   #A55A2E;
$brown-light: #E9D7CD;
$text-blue: #0460C3;
$bg-blue: #C7E2FF;
$green-light: #CCEBCB;
$green-lighter: #E5F5E4;

$colors: ();
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       #2DAA26;
$secondary:     #A2A4A0;
$success:       #AFDFAC;
$info:          #0460C3;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);


$light-gray-border:                       1px solid #DEDFDD;

$link-color:                              theme-color("primary");
$link-decoration:                         none;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   underline;

@if $lang == "ar" {
  $font-family-sans-serif:      'Noto Kufi Arabic', sans-serif;
} @else {
  $font-family-sans-serif:      'Gothic A1', sans-serif;
}
$font-family-base:            $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;

$line-height-base:            1.25;
$line-height-sm:              1.2;
$line-height-lg:              2;

$h1-font-size:                $font-size-base * 6;
$h2-font-size:                $font-size-base * 3.75;
$h3-font-size:                $font-size-base * 3;
$h4-font-size:                $font-size-base * 2.125;
$h5-font-size:                $font-size-base * 1.5;
$h6-font-size:                $font-size-base * 1.25;

$navbar-light-hover-color:          $success;
$navbar-light-color:                rgba($primary, 1);

$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

$pagination-padding-y:              .375rem;
$pagination-padding-x:              .75rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;
$pagination-color:                  $link-color;
$pagination-bg:                     $white;
$pagination-border-width:           $border-width;
$pagination-border-radius:          $border-radius;
$pagination-margin-start:           -$pagination-border-width;
$pagination-border-color:           $gray-300;
$pagination-focus-color:            $link-hover-color;
$pagination-focus-bg:               $gray-200;
$pagination-focus-box-shadow:       $input-btn-focus-box-shadow;
$pagination-focus-outline:          0;
$pagination-hover-color:            $link-hover-color;
$pagination-hover-bg:               $gray-200;
$pagination-hover-border-color:     $gray-300;
$pagination-active-color:           $component-active-color;
$pagination-active-bg:              $component-active-bg;
$pagination-active-border-color:    $pagination-active-bg;
$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;
$pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
$pagination-border-radius-sm:       $border-radius-sm;
$pagination-border-radius-lg:       $border-radius-lg;

$breadcrumb-padding-y:              1rem;
$breadcrumb-padding-x:              1rem;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          0.5rem;

$breadcrumb-bg:                     $white;
$breadcrumb-divider-color:          #868E96;
$breadcrumb-active-color:           #868E96;
$breadcrumb-divider:                quote("/");

$breadcrumb-border-radius:          $border-radius;


$btn-border-radius:           22px;
$btn-border-radius-lg:        $btn-border-radius;
$btn-border-radius-sm:        $btn-border-radius;

$btn-padding-y:               11px;
$btn-padding-x:               20px;
$btn-font-size:               0.9375rem;
$btn-line-height:             1rem;

$btn-box-shadow:              none;
$btn-active-box-shadow:       none;
