img {
  max-width: 100%;
  height: auto;
}
.btn {
  height: 36px;
}
.box-shadow {
  box-shadow: 0px 2px 26px #0000001A;
}
.rounded-12 {
  border-radius: 12px;
}
.rounded-all {
  border-radius: 50%;
}
.auction-card, .auction-card span {
  display: block;
}
.rounded-img.auction-image {
  max-width: 145px;
}
.auction-image img{
  max-width: 76px;
  width: 100%;
  max-height: 76px;
  border-radius: 50%;
  object-fit: cover;
}
.auction-card {
  position: relative;
  margin-top: 20px;
  margin-right: $grid-gutter-width/2;
  margin-left: $grid-gutter-width/2;
  margin-bottom: $grid-gutter-width;
  width: 265px;
  min-height: 475px;
  height: auto;
  padding: 1.2rem 0.825rem 0.6rem;
  text-decoration: none;
  text-align: center;
  color: $body-color;
  & .rounded-img {
    width: 170px;
    height: 170px;
  }
  &.premium {
    border: $primary solid 2px;
    border-radius: 0.4rem;
    @if $lang == "ar" {
      border-bottom-right-radius: 0;  
    } @else {
      border-bottom-left-radius: 0;
    }
  }
  & .certified {
    @if $lang == "ar" {
      left: 14px;
    } @else {
      right: 14px;
    }
  }
  & .auction-title {
    font-size: 1.25rem;
  }
}
.rounded-img {
  border-radius: 50%;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: auto;
}
.pt-56 {
  padding-top: 56px;
}
.pt-42 {
  padding-top: 42px;
}
.card {
  position: relative;
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
  margin-bottom: $grid-gutter-width;
  &.premium {
    border: $primary solid 2px;
    border-radius: 0.4rem;
    @if $lang == "ar" {
      border-bottom-right-radius: 0;  
    } @else {
      border-bottom-left-radius: 0;
    }
  }
  & .max-w-110 {
    max-width: 110px;
  }
  & h5 {
    font-size: 1.5rem;
    font-weight: 900;
    color: $black;
  }
  & .rounded-img {
    max-width: 146px;
    width: 80px;
    height: 80px;
  }
  & .date-code {
    padding-bottom: 16px;
    font-size: 0.9375rem;
    line-height: 1.3125rem;
    text-transform: uppercase;
    color: $gray-600;
    font-weight: 400;
  }
  & .date-info {
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  & .btn {
    width: 100%;
    max-width: 150px;
    border-radius: 21px;
    font-family: $font-family-base;
  }
  & .pt-42 {
    border-top: $gray-300 solid 1px;
    &.no-border {
      border: 0;
    }
    & .btn {
      max-width: 210px;
    }
  }
  & .col-md-6, & .col-md-4, & .col-md-2 {
    padding-bottom: $grid-gutter-width;
  }
  & .bid-status {
    margin-top: 14px;
    padding-bottom: 21px;
    font-size: 1.25rem;
    & b {
      font-weight: 900;
    }
  }
  & .card-info {
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.25px;
    min-height: 130px;
    height: auto;
  }
  & .edit-auction {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray-900;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  & .side-border .px-4 {
    border-bottom: $gray-300 solid 1px;
    margin-bottom: 14px;
  }
}
.premium-border {
  position:absolute;
  display: inline-block;
  width: 8px;
  height: 12px;
  bottom: -2px;
  @if $lang == "ar"{
    right: -2px;
    -webkit-transform: scalex(-1);
    transform: scalex(-1);
  } @else {
    left: -2px;
  }
  background: url(../images/premium-border.svg) 0 bottom no-repeat;
}
.favorite-it {
  position: absolute;
  display: inline-block;
  @if $lang == "ar" {
    right: 16px;
  } @else { 
    left: 16px;
  }
  top: 16px;
  width: 24px;
  height: 24px;
  background: url(../images/heart.svg) 0 0 no-repeat;
  z-index: 100;
  &:hover, &.active {
    background: url(../images/heart-green.svg) 0 0 no-repeat;
  }
}
.title-box .favorite-it {
  position: static;
}

.bid-time {
  padding: 10px;
  border: 1px solid $gray-400;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: $gray-500;
  & span {
    display: inline-block;
  }
}
.bid-time.text-blue {
  border: #C7E2FF solid 1px;
}
.bid-time.text-danger {
  background: #FFEBEC;
  border: #FAD2D5 solid 1px;
}
.bid-time.text-blue {
  color: $text-blue;
  background: $bg-blue;
}
.text-blue {
  color: $text-blue;
}
.bid-price-boxes{
  padding: 0.5rem 0;
  color: $gray-500;
  & table {
    width:100%;
    & td {
      padding: 0.8rem;
    }
  }
  & .rounded {
    border: $gray-300 solid 1px;
    overflow: hidden;
  }
  & .bg-danger-light, & .bg-success-light {
    font-size: 0.6875rem;
  }
  & .bg-success-light b{
    font-size: 1.25rem;
  }
  // & .text-danger {
  //   color: $bid-color !important;
  // }
  & sup {
    font-size: 0.65rem;
    top: -0.8em;
  }
}
.bg-danger-light{
  background: #FFF3EB;
  border-right: $gray-300 solid 1px;
}
.bg-success-light{
  background: #F4FFED;
}
.buy-action {
  font-size: 1rem;
  color: $gray-500;
  & label {
    font-size: 0.625rem;
    text-transform: uppercase;
    & b {
      font-size: 1rem;
      & sup {
        font-size: 0.625rem;
        font-weight: 500;
      }
    }
  }
  & .btn {
    font-size: 0.9375rem;
  }
}

.auction-title {
  margin: 0.825rem 0 0.2rem 0;
  font-weight: 500;
}
.auction-subtitle {
  margin-bottom:2px;
  font-size: 0.9375rem;
  font-weight: normal;
  text-decoration: uppercase;
}
.certified {
  position: absolute;
  width: 25px;
  height: 72px;
  top: 0px;
  @if $lang == "ar" {
    left: 10px;
  } @else {
    right: 10px;
  }
  @if $lang == "ar" {
    background: url(../images/certified-ar.svg) 0 0 no-repeat;
  } @else {
    background: url(../images/certified.svg) 0 0 no-repeat;
  }
  z-index: 2;
}
.auction-info {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.3rem;
  & b {
    font-weight:700;
  }
}
.bid-box {
  width: 80%;
  margin-top: 0.825rem;
  margin-bottom: 0.825rem;
  & table {
    width: 100%;
    text-align: center;
  }
  & td {
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: 0.5rem;
    &.highest-bid {
      font-size: 0.75rem;
      color: $gray-500;
    }
    &.highest-bid div{
      padding-top: 0.25rem;
      font-size: 1.25rem;
      font-weight: bold;
      color: $bid-color;
      & sup {
        font-size: 0.65rem;
        top: -0.6em;
      }
    }
  }
}
.color-500 {
  color: #6C6F69;
}
.extra-buttons {
  margin: 18px -6px 8px;
  & .btn {
    font-size: 0.9375rem;
  }
}
.buy-box {
  border: #DEDFDD solid 1px;
  padding: 16px 10px;
  color: #6C6F69;
}
.buy-label {
  font-size: 0.875rem;
  padding-bottom:0.4rem;
}
.section-title {
  font-size: 1.5rem !important;
  font-weight: 900;
  color: $primary;
}
h1.section-title {
  font-size: 1.5rem !important;
  letter-spacing: 0px;
  color: #2A3D1C;
}
.side-menu {
  &.list-group {
    border-radius: 0;
    margin-bottom: 2rem;
  }
  & .list-group-item {
    padding: 0;
  }
  & a {
    display: block;
    padding: 1.4rem 1.25rem;
  }
  & .active {
    background-color: transparent;
    border-color: rgba(0,0,0,0.125);
    & a {
      background: #F4FFED;
    }
  } 
  & .active a:hover {
    background: #F4FFED;
  }
  & a:hover {
    background: #EBEDE7;
    text-decoration: none;
  }
}
.noti-count {
  position:absolute;
  top: 50%;
  margin-top: -13px;
  padding-top: 4px;
  @if $lang == "ar" {
    left: 15px;
  } @else {
    right: 15px;
  }
  display: inline-block;
  width: 24px;
  height: 24px;
  background:#E84343;
  border-radius: 50%;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.4em;
  color: $white;
}

.bid-status.shipping {
  color: #1360C3;
}
.bid-status.shipped {
  color: $primary;
}
.bid-status.closed {
  color: $red;
}
.bid-status.winner, .bid-status.pending-payment, .bid-status.successful, .bid-status.seller-paid {
  color: #1360C3;
}
.bid-status i {
  display: inline-block;
  width: 50px;
  min-width:40px;
  height: 50px;
  background: url(../images/received.svg) 0 0 no-repeat;
}
.bid-status.pending-payment i {
  background: url(../images/payment.svg) 0 0 no-repeat;
}
.card .bid-status.winner i {
  width: 42px;
  height: 42px;
  background: url(../images/win-l.svg) 0 0 no-repeat;
  background-size: 42px 42px;
}
.bid-status.shipping i {
  background: url(../images/delivery-blue.svg) 0 0 no-repeat;
  background-size: 50px 50px;
}
.bid-status.shipped i {
  width: 80px;
  background: url(../images/success.svg) 0 0 no-repeat;
  background-size: 50px 50px;
}
.bid-status.seller-paid i {
  width: 80px;
  background: url(../images/dollar.svg) 0 0 no-repeat;
  background-size: 50px 50px;
}
.bid-status.successful i {
  width: 80px;
  background: url(../images/successful.svg) 0 0 no-repeat;
  background-size: 50px 50px;
}
.bid-status.closed i {
  margin-top: -3px;
  width: 40px;
  height: 42px;
  background: url(../images/closed.svg) 0 0 no-repeat;
  background-size: auto;
}
.card {
  & .bid-status i {
    background-size: 40px;
  }
}
.transaction-number {
  margin-top: -10px;
  margin-bottom:14px;
  font: normal normal 500 14px/12px Gothic A1;
  letter-spacing: 1.4px;
  color: #1360C3;
}

@media (min-width: map-get($grid-breakpoints, "sm")) {
  .auction-card {
    width: 254px;
  }
}
@media (min-width: map-get($grid-breakpoints, "md")) {
  .certified {
    @if $lang == "ar" {
      left: 24px;
    } @else {
      right: 24px;
    }
  }
  .auction-card {
    width: 225px;
  }
  .auction-image img{
    max-width: 145px;
    max-height: 145px;
    border-radius: 50%;
    object-fit: cover;
  }
  .card {
    & .date-code {
      padding-bottom: 38px;
    }
    & .date-info {
      padding-bottom: 23px;
    }
    & .side-border {
      border-bottom: 0;
      & .px-4{
        border-bottom: 0;
        margin-bottom: 0;
      }
      @if $lang == "ar" {
        border-left: $gray-300 solid 1px;
      } @else {
        border-right: $gray-300 solid 1px;
      }
    }
    & .col-md-6 {
      border-top: 0;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
  .auction-card {
    width: 253px;
  }
}
