.btn-watch {
    display: inline-block;
    margin: 0 14px;
    font-size: 14px;
}
.larger-text {
    line-height: 1.75rem;
    & b {
        font-size: 1.25rem;
        color: $gray-800;
    }
}
.mt-2-5 {
    margin-top: 1.25rem;
}
.mt-13 {
    margin-top: 13px;
}
.details-text {
    color: $gray-600;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    & .label {
        font-size: 0.6875rem;
        text-transform: uppercase;
        & i {
            font-size: 1.25rem;
            position: relative;
            top: 3px;
        }
    }
    & .certificate {
        position: absolute;
        top: -30px;
        width: 25px;
        height: 72px;
        @if $lang == "ar" {
            left: 10px;
            background: url(../images/certified-ar.svg) 0 0 no-repeat;
        } @else {
            right: 10px;
            background: url(../images/certified.svg) 0 0 no-repeat;
        }
    }
    & .grey-box {
        border: #E4E8DE solid 1px;
        background: $white;
        padding: 28px 29px;
        border-radius: 8px 8px 0px 0px;
        &.certificate-box {
            border-radius: 0px 0px 8px 8px;
        }
    }
}
.filters {
    font-size: 1rem;
    color: $gray-500;
}
.filter-navs {
    margin-bottom: 18px;
    white-space: nowrap;
    overflow: auto;
    & a {
        display: inline-block;
        padding: 2px;
        font-size: 1rem;
        color: $gray-500;
        text-decoration: none;
        border-bottom: 2px solid $white;
        &:hover {
            color: $primary;
            text-decoration: none;
        }
        &.active {
            color: $primary;
            border-bottom: 2px solid $primary;
        }
    }
    & ul {
        overflow-x: auto;
        white-space: nowrap;
        list-style: none;
        margin: 0;
        padding: 0 0 12px;
    }
    & li {
        display: inline-block;
        margin: 0 10px;
    }
}

.coming-soon-bg {
    background: #fff;
    opacity: 0.1;
    position: absolute;
    width: 100%;
    height: 250px;
    bottom: 0;
}
.coming-soon-content {
    filter: blur(4px);
    -webkit-filter: blur(4px);
}
.coming-soon-label {
    position: absolute;
    width: 100%;
    bottom: 100px;
    font-weight: bold;
    font-size: 22px;
    color: #267819;
    & i {
        display: inline-block;
        width: 42px;
        height: 42px;
        background: url(../images/time-green.svg) 0 0 no-repeat;
    }
}
.btn-group .btn {
    padding-left: 8px;
    padding-right: 8px
}
.btn-group.browse-auctions-btns .btn {
    padding: 15px 13px;
    border: #EBEDE7 solid 1px;
    border-radius: 4px !important;
    height: 44px;
    &:hover {
        border: $primary solid 1px;
        background: #F4FFED;
        color: $primary;
    }
}
.btn-group.browse-auctions-btns .btn.active {
    background: #F4FFED;
    color: $primary;
    border-color: #EBEDE7;
}

.pagination {
    & a {
        display: inline-block;
        padding: 4px 6px 3px;
        border: solid 1px #AFDFAC;
        border-radius: 2px;
        color: #AFDFAC;
        @if $lang == "ar" {
            margin-left: 6px;
        } @else {
            margin-right: 6px;
        }
        &:hover, &.next-page {
            border: solid 1px $primary;
            color: $primary;
        }
        &.current-page {
            border: solid 1px $primary;
            background: $primary;
            color: $white;
            opacity: 1;
        }
    }
}
.pagination a:hover {
    text-decoration: none;
    opacity: 1;
}
.pagination a.current-page {
    background: #2DAA26;
    color: #fff;
    opacity: 1;
}

.btn-group-toggle>.btn, .btn-group-toggle>.btn-group>.btn {
    white-space: nowrap;
}
.hover-effect:hover, .hover-effect:hover * {
    background: $primary;
    color: $white !important;
}
.invoices-list{
    @if $lang == "ar" {
        padding-left: 18px;
    } @else {
        padding-right: 18px;
    }
    max-height: 260px;
    height: auto;
    overflow-y: auto;
}
.invioce-item{
    padding: 19px 25px 16px;
    color: #6C6F69;
    background: #DEDFDD;
    border-radius: 9px;
}
.invoices-list .invoice-amount{
    @if $lang == "ar" {
        text-align: left;
    } @else {
        text-align: right;
    }
}
#otp-form .verify-inputs {
    direction: ltr;
}
.w-80 {
    width: 80%;
}
.grey-box .col-md-8 {
    padding-left: 38px;
    padding-right: 38px;
}
.little-title {
    font-size: 0.8125rem;
    text-transform: uppercase;
}
.gallery img {
    border-radius: 8px;
}
.thumb-img {
    width: 60px;
    height: 60px;
}
#bidTabs {
    border-bottom: 0;
}
.nav-tabs .nav-link {
    background: $white;
    border: 0;
    border-bottom: 1px solid #C6EBAE;
    color: $primary;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: $primary;
    background: #F4FFED;
    border: 1px solid #C6EBAE;
    border-bottom: 0;
}
#bidTabsContent {
    // margin-top: -1px; // to remove part of the border.
    padding: 20px;
    background: $white;
    border: 1px solid #C6EBAE;
    border-radius: 0 8px 8px 8px;
    color: $primary;
    & .bid-status {
        padding-top: 20px;
        padding-bottom: 32px; 
        font-size: 1.25rem;
    }
    & .btn-primary {
        width: 70%;
        border-radius: 20px;
    }
}
.bid-action-btns {
    margin-top: -100px;
    font-size: 28px;
}
.bid-price-input{
    background: transparent;
    padding: 0 0.5rem;
    border: 0;
    width: 100%;
    text-align:right;
    font-weight: 900;
    font-size: 1.5rem;
    color: $primary;
    outline: 0;
}
.price-input-box {
    width: 60%;
}
.bid-currency {
    width:40%;
    font-size:1.25rem;
    font-weight: 600;
    line-height: 1.8em;
}
.other-currency {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    color: #2A2E26;
}
.mt-10 {
    margin-top: 10px;
}
.your-bid {
    padding-top: 32px;
    padding-bottom: 10px;
    font-size: 14px;
    color: #A2A4A0;
}
.your-bid-total {
    font-size: 30px;
    font-weight: bold;
    color: $gray-900;
}
.increase-bid-btn i {
    display: inline-block;
    width: 24px; 
    height: 24px;
    @if $lang == "ar" {
        margin-left: 7px;
    } @else {
        margin-right: 7px;
    }
    background: url(../images/increase-btn.svg) 0 0 no-repeat;
}
.buy-direct-box {
    margin-top: 8px;
    padding: 50px 15px 40px;
    border: #E4E8DE solid 1px;
    border-radius: 8px;
    color: $primary;
    & .label {
        padding-top: 20px;
        text-transform: uppercase;
        font-size: 0.8125rem;
    }
    & .direct-buy-price {
        padding-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
        & sup {
            font-size: 1rem;
        }
    }
    & .btn {
        width:60%;
    }
}
.edit-account .border-bottom:last-child {
    border-bottom: 0 !important;
}
.mt--20{
    margin-top:-20px;
}
.edit-account-action {
    margin-bottom:-44px;
    text-align: end;
}
.alert.alert-blacklisted {
    padding: 40px 25px;
    line-height: 21px;
    color: #B01220;
    & .warning-icon-large {
        display: inline-block;
        margin-bottom: 34px;
        width: 69px;
        height: 59px;
        background: url(../images/warning-l.svg) 0 0 no-repeat;
    }
    & b {
        line-height: 32px;
    }
}
.auction-thumb {
    width: 85px;
    height: 85px;
    margin-right: 20px;
    & img {
        width: 100%;
        height: auto;
    }
    & .auction-thumb-img {
        width: 85px;
        height: 85px;
    }
}
.title-box {
    margin-bottom: 32px;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-90 {
    margin-bottom: 40px;
}
.alert {
    font-size: 0.875rem;
    & img {
        margin-top: -1px;
        margin-right: 20px;
    }
}
.tax-note{
    padding: 13px;
    background: #DEDFDD;
    text-align:center;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #000000;
    border-radius: 8px;
}
.section-serial-number{
    font-size: 15px;
}
.cards.buyer-contries {
    & .tabs-holder{
        border-bottom: #CCEBCB;
        margin-bottom: 60px;
    }
    & .nav-tabs .nav-link{
        border: 0;
        border-bottom: 1px solid #C6EBAE;
        margin-bottom: -1px;
        font-size: 18px;
        color: #C6EBAE;
    }
    & .nav-tabs .nav-link.active, & .nav-tabs .nav-item.show .nav-link, & .nav-tabs .nav-link:hover {
        background: $white;
        color: $primary;
        border-bottom: 1px solid $primary;
    }
}
@if $lang == "ar" {
    .form-check {
        padding-left:0;
        padding-right: 1.25rem;
    }
    .form-check-input {
        margin-left:0;
        margin-right: -1.25rem;
    }
    .inv-checkbox {
        padding-right: 0;
        padding-left: 15px;
    }
}
.side-box {
    margin-top: 20px;
    padding: 20px 0;
    border: #E4E8DE solid 1px;
    font-size: 0.75rem;
    & .side-box-content {
        padding: 0 30px;
    }
    & .section-title {
        padding-bottom: 11px;
        font-size: 0.9375rem !important;
        font-weight: 500;
        text-align: center;
    }
    & .history-totals {
        padding: 4px;
        background: $gray-100;
        text-transform: uppercase;
    }
    & .question-title {
        margin-bottom: 20px;
        font-size: 0.9375rem;
        color: $primary;
    }
    & .call-center, & .buy-safely {
        padding-bottom: 20px;
        border-bottom: #EBEDE7 solid 1px;
    }
    & .buy-safely {
        font-size: 0.9375rem;
        line-height: 1.3125rem;
        color: $gray-600;
        & label {
            margin-bottom: 23px;
            display: block;
            padding-top: 5px;
            text-transform: uppercase;
            color: #0B1006DE;
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.42em;
        }
        & i.check {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url(../images/safety-item.svg) 0 0 no-repeat;
        }
    }
    & .payment-options {
        padding-bottom: 10px;
        & img {
            height: 16px;
            width: auto;
        }
        & label {
            display: block;
            padding-top: 30px;
            text-transform: uppercase;
        }
    }
    & .las {
        font-size: 1rem;
    }
}
.history-items {
    height: 140px;
    overflow-y: auto;
    & .table th {
        font-size: 0.6875;
        text-transform: uppercase;
        color: $gray-500;
    }
    & .table th, & .table td {
        padding: 6px 16px;
    }
    & .table td {
        color: $gray-400;
    }
    & .table td.price {
        color: $gray-600;
    }
}
.rounded-8 {
    border-radius: 8px;
}
.rounded-20 {
    border-radius: 20px;
}
.breadcrumb {
    font-size: 0.875rem;
    font-weight: 400;
}
@if $lang == "ar" {
    .custom-select {
        padding: .375rem .75rem .375rem 1.75rem;
    }
    .modal-header .close {
        margin: -1rem auto -1rem -1rem;
    }
    .alert-info i{
        margin-right: unset;
        margin-left: 12px;
    }
}
.page-content{
    font-family: 'Noto Sans';
    font: normal normal normal 22px/36px 'Noto Sans';
    color: #000;
}
.hero-unit {
    margin-top:-50px;
    padding: 30px 0 80px;
    background: #2DAA26;
    font: normal normal normal 18px/27px 'Noto Sans';
    color: #fff;
}
.hero-unit h2{
    font: normal normal bold 50px/60px 'Noto Sans';
}
.page-content h3{
    font: normal normal bold 60px/70px 'Noto Sans';
    color: $primary;
}
.header-p {
    max-width:520px;
}
.width-500{
    width: auto;
    max-width: 500px;
}
a u{
    text-decoration: underline;
}
.green-bg{
    background:#E5F5E4;
}
.join-label{
    font: normal normal bold 24px/50px 'Noto Sans';
    color: $primary;
}
.rounded-20{
    border-radius: 20px;
}
.footer-join .btn {
    min-width: 280px;
}


.card.notification{
    margin-bottom: 17px;
    padding: 26px 28px;
}
.notification{
    font-size: 14px;
    line-height: 18px;
    color: #6C6F69;
    border: 2px solid #DEDFDD;
    @if $lang == "ar" {
        border-radius: 10px 0px 10px 10px;
    } @else {
        border-radius: 10px 10px 10px 0px;
    }
    &, & > span {
        display: block;
    }
    &:hover, &:hover > span {
        color: #6C6F69;
        text-decoration: none;
    }
    &.unread{
        border-color: #AFDFAC;
    }
    & .event-date{
        font-size: 12px;
        line-height: 1em;
        letter-spacing: 1.2px;
    }
    & .event-subject {
        padding: 10px 0;
        font-size: 18px;
        line-height: 26px;
        font-weight: bold;
        letter-spacing: 0.32px;
        color: #2A2E26;
    }
    & b, & .event-subject a {
        text-decoration: underline;
        color: $primary;
    }
}
.listing-item img{
    min-width: 30px;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}
.object-fit{
    object-fit: cover;
}

@media (min-width: map-get($grid-breakpoints, "md")) {
    .cards.buyer-contries {
        & .nav-tabs .nav-link{
            font-size:31px;
            @if $lang == "ar" {
                margin-left: 60px;
            } @else {
                margin-right: 60px;
            }
        }
    }
    .btn-group.browse-auctions-btns .btn {
        padding: 15px 26px;
    }
    .hero-unit h2{
        font-size: 80px;
        line-height: 90px;
    }
    .btn-group .btn {
        padding-left: 20px;
        padding-right: 20px
    }
    .pagination a {
        padding: 6px 10px 4px;
        @if $lang == "ar" {
            margin-left: 16px;
        } @else {
            margin-right: 16px;
        }
    }
    .mb-90 {
        margin-bottom: 90px;
    }
    .auction-thumb {
        width: 100px;
        height: 100px;
        & .auction-thumb-img {
            width: 100px;
            height: 100px;
        }
    }
    .grey-box .col-md-8 {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    } 
    .thumb-img {
        width: 70px;
        height: 70px;
    }
    .footer-join .btn {
        min-width: 160px;
    }
}