.login-title {
    padding-top: 7px;
    margin-bottom: 40px;
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #2A2E26;
    &.fp-title {
        text-transform: capitalize;
    }
}
.login-btn {
    padding-top: 58px;
    & .btn {
        padding-left: 60px;
        padding-right: 60px;
        line-height: 0.8em;
    }
}
.fp-message {
    margin-bottom: 68px;
    padding: 0 10px;
    color: #2A2E26;
}
.reg-box.login label {
    display: none;
}