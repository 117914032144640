
footer {
    padding-top: 38px;
    padding-bottom: 30px;
    border-top: 1px solid #DEE2E6;
    text-align: center;
    font-weight: 400;
    color: #000;
    & p {
        margin-top: 21px;
    }
    & a {
        color: #000;
    }
    & ul{
        margin: 0;
        & a {
            line-height: 2rem;
            font-weight: 500;
        }
    }
    & .social a {
        font-size: 23px;
    }
    & .btn{ 
        line-height: 1;
    }
}
.join-label, .footer-join .btn{
    white-space: nowrap;
}
.copyright {
    font-size: 0.8125rem;
    color: $secondary;
}

.call-number {
    margin-top: 9px;
    padding-top: 6px;
    @if $lang == "ar" {
        padding-right: 44px;
        background: url(../images/call-us.svg) right 0 no-repeat;
    } @else {
        padding-left: 44px;
        background: url(../images/call-us.svg) 0 0 no-repeat;
    }
    font-size: 22px;
    color: $primary;
    letter-spacing: 0.4px;
    font-weight: bold;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

@media (max-width: 1200px) {
    .call-number {
        font-size:18px;
        padding-bottom: 4px;
    }
}
@media (max-width: 992px) {
    .call-number-label {
        text-align: center;
    }
    .call-number {
        font-size:16px;
        padding: 40px 0 0;
        background-position: center 0;
    }
}
@media (max-width: 767px) {
    .call-number {
        font-size: 22px;
    }
}
@media (min-width: 1024px) {
    footer {
        @if $lang == "ar" {
            text-align:right;
        } @else {
            text-align:inherit;
        }
    }
}