.rounded-10 {
    border-radius: 10px;
}
.card-section {
    padding: 25px 23px;
    margin-bottom: -50px;
}
.newbtn-box {
    margin-bottom: 30px;
    & .btn {
        height: 36px;
        line-height: 1em;
        & span {
            display:inline-block;
            @if $lang == "ar" {
                padding-left: 8px;
            } @else {
                padding-right: 8px;
            }
        }
    }
}
.app-date {
    margin-top:20px;
}
.app-date i, .app-time i {
    display: inline-block;
    width: 30px;
    height: 30px;
    @if $lang == "ar" {
        margin-left: 18px;
    } @else {
        margin-right: 18px;
    }
}
@if $lang == "ar" {
    .app-time {
        direction: ltr;
    }
}
.app-date i, .app-time i, .app-location i {
    @if $lang == "ar" {
        float:right;
    } @else {
        float:left;
    }
}
.app-date, .app-time {
    color: $gray-900;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 20px;
}
.app-date i {
    background: url(../images/calendar-black.svg) 0 0 no-repeat;
}
.app-time i {
    background: url(../images/time.svg) 0 0 no-repeat;
}
.app-location, .app-message {
    padding: 14px 9px;
    border: 1px solid #DEDFDD;
    border-radius: 4px;
    font-size: 0.875rem;
    line-height: 1.3em;
    & i {
        display: inline-block;
        width: 33px;
        min-width:33px;
        height: 26px;
        @if $lang == "ar" {
            margin-left: 12px;
        } @else {
            margin-right: 12px;
        }
    }
}
.app-location {
    margin-bottom: 13px;
    color: $gray-900;
    & i {
        background: url(../images/location.svg) 0 0 no-repeat;
    }
}
#appointment-div-id {
    & .card {
        padding-left: 6px;
        padding-right: 6px;
    }
    & .app-time {
        margin-bottom: 0;
        padding-bottom: 20px;
        border-bottom: #EBEDE7 solid 1px;
    }
}
.app-message {
    color: #1360C3;
    margin-bottom: 25px;
    & i {
        background: url(../images/paper.svg) 0 0 no-repeat;
    }
    &.completed {
        color: $green;
        & i {
            background: url(../images/paper-green.svg) 0 0 no-repeat;
        }
    }
    &.rejected {
        color: $red;
        i {
            background: url(../images/paper-red.svg) 0 0 no-repeat;
        }
    }
}
.app-actions {
    margin-bottom: 25px;
    & a {
        display: inline-block;
        margin-right: 20px;
        font-size: 0.9375rem;
        font-weight: 600;
    }
}
.app-message {
    border-color: #C2D9F1;
}
.app-message.completed {
    border-color: #AFDFAC;
}
.app-message.rejected {
    border-color: #ECC2C9;
}
.modal-content {
    font-size: 1rem;
    color: $gray-900;
    & .title {
        padding: 14px 0 40px;
        font-size: 1.125rem;
        color: $primary;
    }
    & .subtitle {
        font-size: 1.5rem;
        line-height: 1.25em;
        margin-bottom: 16px;
    }
    & .message {
        padding: 16px;
        margin-bottom: 40px;
        font-size: 0.875rem;
        line-height: 1.3em;
        background: #E0ECF8;
        border: 1px solid #C7E2FF;
        border-radius: 4px;
        color: #1360C3;
        word-break: break-word;
    }
    & select, & .form-control {
        height: 70px;
        font-size: 1rem;
    }
}
.modal-dialog {
    margin: 5rem 1.6rem 5rem;
}
#id_login::placeholder, #id_password::placeholder {
    font-size: 0.6em;
}
.modal-header.no-header {
    position: relative;
    height: 0;
    border: 0;
    margin-bottom: -2rem;
    z-index: 200;
}
.modal-form .form-group {
    margin-bottom: 48px;
}
.modal-date {
    font-size: 1rem;
    color: $primary;
    font-weight: 700;
}
.mb-20 {
    margin-bottom: 20px;
}
.modal-dialog {
    max-width: 694px;
}
.modal-body {
    padding: 1rem 1.2rem;
}
.form-footer {
    & .btn {
        width: 124px;
        height: 36px;
        line-height: 1em;
    }
    & .help-text {
        padding-top: 20px;
        font-weight: 500;
        font-size: 0.875rem;
    }
    &.success-btns .btn {
        width: 100%;
        max-width: 276px;
        margin-top: 20px;
    }
    & .btn-outline-primary {
        @if $lang == "ar" {
            margin-left: .5rem;
        } @else {
            margin-right: .5rem;
        }
    }
}
.custom-select {
    background-image: url(../images/arrow.svg);
    background-size: auto;
    appearance: none;
    -webkit-appearance: none;
}
.no-appointments {
    height: 100%;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #6C6F69;
    & .calendar-icon {
        display: inline-block;
        width: 57px;
        height: 57px;
        background: url(../images/calendar-l.svg) 0 0 no-repeat;
    }
    & .subtitle {
        padding: 20px 0 14px;
        font-size: 24px;
        font-weight: 900;
        color: $black;
        letter-spacing: 0px;
    }
    & .new-appointment-btn {
        padding-top: 48px;
    }
}
@media (min-width: map-get($grid-breakpoints, "sm")) {
    .modal-dialog {
        margin: 2rem auto 1rem !important;
    }
}
@media (min-width: map-get($grid-breakpoints, "md")) {
    #id_login::placeholder, #id_password::placeholder {
        font-size: inherit;
    }
    .card-section {
        margin-bottom: 0;
    }
    .form-footer {
        & .btn {
            width: 134px;
        }
    }
    #appointment-div-id {
        & .card {
            padding-left: 15px;
            padding-right: 15px;
        }
        & .app-time {
            border-bottom: unset;
        }
    }
    #appointment-btn {
        @if $lang == "ar" {
            float: left;
        } @else {
            float: right;
        }
    }
    .modal-body {
        margin: 2rem auto 1rem;
        padding: 1rem 114px;
    }
}
