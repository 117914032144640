.page-subtitle {
    padding: 50px 0;
    font-size: 1.25rem;
    font-weight: 500;
    color: $gray-900;
    & .verification-title {
        color: $gray-900;
    }
}
.reg-box .farmer-category {
    padding: 21px 43px;
    background: #EBEDE7;
    font-size: 1.5rem;
    color: #245B12;
    font-weight: bold;
    & span {
        display: block;
        font-weight: 300;
        font-size: 0.875rem;
    }
}
.answer-btns .btn {
    height: 71px;
}
.reg-steps {
    margin-bottom: 30px;
    & div {
        width: 20px;
        height: 6px;
        margin: 0 10px;
        background: $green-light;
        border-radius: 3px;
    }
    & div.active {
        background: $primary;
    }
}
.pt-60 {
    padding-top: 60px;
}
.pt-20 {
    padding-top: 20px;
}
.reg-options.d-flex {
    flex-direction: column;
    & .btn {
        border: $light-gray-border;
        border-radius: 5px;
        height: 71px;
        padding-top: 28px;
        margin-bottom: 1rem;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}
.verification-text {
    font-size: 1rem;
    line-height: 1.5em;
    color: $secondary;
}
.collapse-title, a.collapse-title {
    position: relative;
    display: block;
    background: #EEF8EE;
    color: $primary;
    border-radius: 4px 4px 0 0;
    padding: 27px 42px 20px;
    font-size: 1.125rem;
    line-height: 1.2em;
    font-weight: bold;
    &:hover {
        text-decoration: none;
    }
    &::after {
        display: inline-block;
        position: absolute;
        top: 32px;
        @if $lang == "ar" {
            left: 34px;
        } @else {
            right: 34px;
        }
        content: " ";
        width: 24px;
        height: 14px;
        background-image: url(/dist/assets/images/arrow.svg);
        background-repeat: no-repeat;
        background-position: top left;
    }
    &.collapsed::after {
        top: 28px;
        right: 42px;
        transform: rotate(180deg);
    }
}

.col-reg {
    width: 100%;
    max-width: 600px;
    padding: 0 15px;
    padding-bottom: 26px;
    & .btn {
        min-width: 120px;
    }
    & .next-btn {
        padding-bottom: 70px;
    }
    & .primary-title {
        color: $primary;
        font-size: 1.125rem;
        line-height: 1.5em;
        font-weight: 500;
        & i {
            font-size: 60px;
            color: $primary;
        }
    }
}
.who-box {
    padding: 50px 0 21px;
}
.success-subtext {
    font-size: 0.875rem;
}
.pt-30 {
    padding-top: 30px;
}
.reg-box {
    border-radius: 4px;
    padding: 50px 34px 35px;
    margin-bottom: 33px;
    box-shadow: 0px 3px 6px #00000029;
    @if $lang == "ar" {
        text-align:right;
        direction: rtl;
    }
    & .form-group {
        margin-bottom: 28px;
    }
    &.login .form-control {
        font-size: 1.25rem;
        padding: 27px 25px 23px;
    }
    &.collapsable-box {
        padding: 0;
        & .collapse-wrapper {
            padding: 24px 42px;
        }
    }
    & select, & select.is-invalid {
        padding-right: 1.25rem;
        padding-left:1.25rem;
        content: $lang;
        @if $lang == "ar" {
            background: url(../images/arrow.svg) left 20px center/8px 10px no-repeat;
        } @else {
            background: url(../images/arrow.svg) right 20px center/8px 10px no-repeat;
        }
        background-size: auto;
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        color: $gray-900;
        -webkit-appearance: none;
    }
    & select.is-invalid {
        border: 1px solid #dc3545;
    }
    & select.country-select {
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
    }
    & .fields-title {
        margin-bottom: 30px;
        font-size: 1.125rem;
        color: $primary;
        font-weight: 500;
    }
    & .btn-outline-secondary {
        border-color: #E0E0E0;
        border-radius: 5px;
        text-align: center;
        width: 100%;
        & span.yes {
            position: relative;
            top: -3px;
        }
        & .icon {
            padding-top: 7px;
            @if $lang == "ar" {
                margin-left: 12px;
            } @else {
                margin-right: 12px;
            }
            background: $primary;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.5em;
            border-radius: 50%;
            width: 37px;
            height: 37px;
            color: $white;
            display: inline-block;
            & i {
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url(../images/yes.svg) center top no-repeat;
            }
        }
        &:hover {
            border-color: #E0E0E0;
            background: $primary;
            & .icon {
                background: $white;
                color: $primary;
                & i {
                    background: url(../images/yes-hover.svg) center top no-repeat;
                }
            }
        }
        .form-group {
            margin-bottom: 30px;
        }
    }
    & .asteriskField {
        display: none;
    }
    & .succes-btns {
        & .btn-primary {
            width: 70%;
        }
    }
    & .btn-outline-secondary.later {
        border-radius: 26px;
        width: 70%;
    }
}
.form-label {
    font-size: 1.125rem;
    font-weight: 500;
    padding-bottom: 30px;
}
.attachment-msg {
    margin-top: -0.7rem;
}
.datepicker-icon {
    & label {
        width: 70px;
        background: url(../images/calendar.svg) center 5px no-repeat #F4FFED;
    }
}
.Zebra_DatePicker_Icon_Wrapper::after {
    display: inline-block;
    content: " ";
    position: absolute;
    top: 0;
    @if $lang == "ar" {
        left: 0;
        border-radius: 4px 0 0 4px;
    } @else {
        right: 0;
        border-radius: 0 4px 4px 0;
    }
    width: 70px;
    height: 38px;
    background: url(../images/calendar.svg) center 5px no-repeat #F4FFED;
    border: #ced4da solid 1px;
    cursor: pointer;
}
.attachment .btn.btn-outline-success, .datepicker-label {
    min-width: 80px;
    padding: 3px 12px 0;
    background: #F4FFED;
    border-color: #CED4DA;
    @if $lang == "ar"{
        border-radius: 4px 0px 0px 4px;
    } @else {
        border-radius: 0px 4px 4px 0px;
    }
    color: $primary;
    &:hover {
        color: $primary;
    }
}
.datepicker-label {
    min-width: auto;
}
.mr-22 {
    margin-left: 0;
    margin-right: 0;
}

.attachment .custom-file-label::after {
    display: none;
}
.attachment .btn {
    height: 38px;
}
.verify-inputs {
    & input {
        margin: 0 5px;
        width: 40px;
        border: 0;
        border-bottom: #E0E0E0 solid 2px;
        outline: 0;
        font-size: 22px;
        text-align: center;
    }
}
.counter-text{
    font-size: 1rem;
    line-height: 1.4em;
    font-weight: 700;
    color: $gray-900;
}
.answer-btns {
    padding: 50px 0 0;
}
@if $lang == "ar" {
    .custom-control-input {
        right: 0;
        left: auto;
    }
    .custom-control {
        padding-left: 0;
        padding-right: 1.5rem;
    }
    .custom-control-label::before, .custom-control-label::after {
        left: auto;
        right: -1.5rem;
    }
    .custom-file-input {
        left: 0;
    }
    .input-group-append{
        margin-left: 0;
        margin-right: -1px;
    }
    .input-group>.custom-file:not(:last-child) .custom-file-label, .input-group>.custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }
} @else {
    .input-group>.custom-file:not(:last-child) .custom-file-label, .input-group>.custom-file:not(:first-child) .custom-file-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }
}

@media (min-width: map-get($grid-breakpoints, "md")) {
    .reg-options.d-flex {
        flex-direction: row;
    }
    .col-reg {
        padding-left: 0;
        padding-right: 0;
    }
    .who-box {
        padding: 70px 0 21px;
    }
    .reg-box {
        padding-left: 67px;
        padding-right: 67px;
    }
    .answer-btns {
        padding: 77px 0 0;
    }
    .mr-22 {
        @if $lang == "ar" {
            margin-left: 22px;
        } @else {
            margin-right: 22px;
        }
    }
}