
.badge {
    padding: 15px 18px;
    font-size: 1.125rem;
    border-radius: 8px;
    &.small {
        font-size: 0.625rem;
        & .badge-label {
            text-transform: uppercase;
            font-size: 0.75rem;
        }
        & .time-remaining {
            font-size: 1rem;
        }
    }
    &.small.remaining {
        & .badge-label {
            padding-bottom:4px;
        }
    }
    & .badge-label {
        text-transform: uppercase;
        font-size: 12px;
        white-space: nowrap;
        & .las {
            font-size: 1.8em;
            position: relative;
            top: 4px;
        }
    }
    & .remaining-time-badge{
        font-size: 10px;
        line-height: 10px;
    }
    &.remaining {
        background: $white;
        border: 1px solid #DEDFDD;
        color: $gray-600;
    }
    &.delivered {
        background: $primary;
        color: $white;
    }
    &.closed {
        color: $red;
        border: 1px solid #ECC2C9;
    }
    &.winner {
        color: #1360C3;
        border: 1px solid #C7E2FF;
    }
    &.your-bid {
        background: #F4FFED;
        color: $gray-600;
        border: 1px solid #C6EBAE;
    }
    &.auction-status {
        border-bottom: #DEDFDD solid 1px;
        padding: 8px 0;
        margin-bottom: 18px;
        & i {
            display: inline-block;
            width: 42px;
            height: 42px;
        }
    }
    &.status-received, &.status-shipped {
        color: $primary;
        & i {
            background: url(../images/received.svg) 0 0 no-repeat;
            background-size: 40px;
        }
    }
    &.status-shipped i{
        background: url(../images/success.svg) 0 0 no-repeat;
        background-size: 40px;
    }
    &.status-closed {
        color: $red;
        & i {
            background: url(../images/closed.svg) 0 0 no-repeat;
            background-size: 40px;
        }
    }
    &.status-won {
        color: #0460C3;
        & i {
            background: url(../images/win.svg) 0 0 no-repeat;
            background-size: 40px;
        }
    }
    & .info-text {
        color: $gray-700;
        font-size: 0.875rem;
    }
    & a {
        font-size: 0.9375rem;
    }
}
.mb-8 {
    margin-bottom: 8px;
}
.mr-15 {
    @if $lang == "ar" {
        margin-left: 15px;
    } @else {
        margin-right: 15px;
    }
}
.flex-column .mr-15 {
    margin-right: 0;
    margin-left: 0;
}
.time-remaining {
    font-size: 1rem;
}
.remaining i, .winner i, .closed i, .delivered i, .closed i {
    display: inline-block;
    @if $lang == "ar" {
        margin-left: 5px;
    } @else {
        margin-right: 5px;
    }
    width: 25px;
    height: 19px;
    background: url(../images/timer.svg) 0 0 no-repeat;
}
.winner i {
    min-width: 24px;
    height: 26px;
    background: url(../images/win.svg) 0 0 no-repeat;
}
.closed i {
    width: 26px;
    height: 26px;
    background: url(../images/closed-2.svg) 0 0 no-repeat;
}
.delivered i {
    background: url(../images/delivery.svg) 0 -4px no-repeat;
}
.badge.small .time-remaining {
    font-size: 0.7rem;
    padding-top: 5px;
}
@if $lang == "ar" {
    .card .btn-lost.ml-3 {
        margin-right: 1rem;
        margin-left: 0 !important;
    }
}


.current-price-box {
    padding: 20px 14px;
    color: $primary;
    border: #C6EBAE solid 1px;
    font-size: 0.8125rem;
    & .price-info {
        font-size: 10px;
        padding-top: 8px;
        padding-bottom: 0.6875rem;
    }
    &.large .price-info {
        font-size: 1rem;
    }
    & .label {
        text-transform: uppercase;
    }
    & .text-bid {
        & sup {
            margin-top: -6px;
            font-size: 0.6rem;
        }
    }
}

.opening-price-box {
    padding: 20px 14px;
    border: #EDDCD0 solid 1px;
    color: $brown;
    font-size: 0.8125rem;
    & .label {
        text-transform: uppercase;
    }
    & .price-info {
        padding-top: 10px;
        padding-bottom: 8px;
        font-size: 1rem;
        & sup {
            margin-top: -6px;
            font-size: 0.6rem;
        }
    }
    &.your-bid {
        padding: 10px 14px;
        border: $primary solid 1px;
        border-radius: 4px;
        color: $primary;
    }
}

.connected {
    & .current-price-box {
        height: 114px;
        @if $lang == "ar" {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        } @else {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    & .opening-price-box {
        height: 114px;
        @if $lang == "ar" {
            border-right: 0;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        } @else {
            border-left: 0;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }
}

.small-pricing {
    & .opening-price-box, & .current-price-box {
        border-radius: 8px;
        width:50%;
    }
    & .current-price-box {
        @if $lang == "ar" {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        } @else {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    & .opening-price-box {
        @if $lang == "ar" {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        } @else {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    & .opening-price-box .price-info, & .current-price-box .text-bid {
        padding-top: 10px;
        padding-bottom: 8px;
    }
    & button {
        border-radius: 21px;
    }
}
.rounded-img {
    & .certified {
        top: 110px;
        right: 25%;
    }
}
.bid-status .las {
    font-size: 42px;
    opacity: 0.5;
}
.badge .flex-fill.text-left{
    white-space: nowrap;
}
.current-price-box .price-info .price{
    font-size: 1rem;
}
@media (min-width: map-get($grid-breakpoints, "md")) {
    .flex-column .mr-15 {
        @if $lang == "ar" {
            margin-left: 15px;    
        } @else {
            margin-right: 15px;
        }
    }
    .rounded-img {
        & .certified {
            top: 168px;
            width: 70px;
            height: 80px;
        }
    }
}
@media (min-width: map-get($grid-breakpoints, "lg")) {
    .badge.small .time-remaining {
        font-size: 0.7rem;
        padding-top: 0px;
    }
}
@media (min-width: map-get($grid-breakpoints, "xl")) {
    .badge.small .time-remaining {
        font-size: 1rem;
        padding-top: 0px;
    }
}
