.edit-container {
    margin: 24px;
    max-width: 503px;
    & .sub-title {
        font-size: 0.625rem;
        color: $gray-700;
        text-transform: uppercase;
    }
    & .buttons {
        padding: 50px 0 26px;
    }
}
.form-group.muted {
    color: #A2A4A0;
}
.listing-type {
    & .sub-title {
        padding-top: 20px;
        border-top: 1px solid $gray-300;
        letter-spacing: 1px;
    }
    & label {
    width: 75%;
    padding: 12px 10px;
    }
}
.form-title {
    margin-bottom: 38px;
    color: #212529;
    font-weight: 700;
}
.general-section {
    padding-bottom: 45px;
    margin-bottom: 28px;
    border-bottom: 1px solid $gray-300;
}
.mb-12 {
    margin-bottom: 12px;
}
.back-link {
    & a {
        font-size: 0.75rem;
        color: $gray-700;
        text-transform: uppercase;
        &:hover {
            text-decoration: none;
            color: $success;
        }
        & i {
            position: relative;
            left: -14px;
            top: 5px;
            font-size: 1.5rem;
        }
    }
}
.edit-container .alert-info {
    margin-bottom: 49px;
}
.alert-info {
    & i {
        font-size: 1.5rem;
        margin-right: 12px;
    }
}


.switch {
    position: relative;
    margin: 5px 0;
    display: inline-block;
    width: 40px;
    height: 34px;
    & input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
    & .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 14px;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: -6px;
            bottom: -5px;
            background-color: $primary;
            -webkit-transition: .4s;
            transition: .4s;
        }
        &.round {
            border-radius: 34px;
            &:before {
                border-radius: 50%;
            }
        }
    }
    & input:checked + .slider {
        background-color: $success;
    }  
    & input:focus + .slider {
        box-shadow: 0 0 1px $primary;
    }
    & input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
  }
  