.my-wallet {
    line-height: 1em;
    & .balance {
        @if $lang == "ar" {
            margin-left: 27px;
        } @else {
            margin-right: 27px;
        }
    }
    & .balance, & .credit-limit {
        padding: 20px 16px;
        width: 50%;
        font-size: 0.96rem;
        font-weight: 500;
        letter-spacing: 0.34px;
        color: $primary;
        text-transform: uppercase;
        white-space: nowrap;
    }
    & label {
        display: block;
        margin-bottom: 13px;
        font-size: 0.625rem;
        color: #0B1006;
    }
    & form label {
        margin-bottom: 0;
    }
    & .form-group label {
        padding-bottom: 8px;
        font-size: 0.75rem;
        color: $primary;
    }
    & .form-check {
        margin-bottom: 14px;
        padding: 20px;
        border: 1px solid #DEDFDD;
        border-radius: 4px;
        & label {
            color: #6C6F69;
            font-size: 1rem;
            letter-spacing: 0.5px;
        }
        & .form-check-input {
            top: 18px;
            right: 22px;
        }
    }
    & .add-funds .main-label {
        display: block;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    & .action {
        padding-top: 23px;
        & .btn { 
            padding-left: 44px;
            padding-right: 44px;
        }
    }
}
.add-funds {
    margin: 25px 0;
    padding: 28px;
    background: #FAFAFA;
    border-radius: 9px;
    & .form-control {
        border: 2px solid $primary;
    }
}
.copy-btn {
    position: absolute;
    top: 15px;
    @if $lang == "ar" {
        left: 15px;
    } @else {
        right: 15px;
    }
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url(../images/copy.svg) 0 0 no-repeat;
}
#bankDetails {
    line-height: 1.5em;
}

.modal-content {
    label.text-primary {
        font-size: 0.75rem;
        letter-spacing: 0.4px;
    }
    .form-control.height-auto {
        height: auto;
    }
}
@media (min-width: map-get($grid-breakpoints, "md")) {
    .my-wallet {
        & .balance, & .credit-limit {
            font-size: 1.1875rem;
        }
    }
}